// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "d2_Jd";
export var authorHead__aboutAuthorTitle = "d2_Jc";
export var authorHead__aboutAuthorWrapper = "d2_Jb";
export var authorHead__description = "d2_H8";
export var authorHead__flexWrapper = "d2_H4";
export var authorHead__name = "d2_H6";
export var authorHead__photo = "d2_H5";
export var authorHead__position = "d2_H7";
export var authorHead__socialWrapper = "d2_H9";
export var authorHead__wrapper = "d2_H3";